<template lang="pug">
q-layout(view="lHh Lpr lFf")
  router-view
</template>

<script>
export default {
  name: "Submission",
  data: () => {
    return {
      phases: {},
    };
  },
};
</script>